









import { Component, Vue, Prop } from "vue-property-decorator";
export default class TheFooter extends Vue {
  private publicPath = process.env.VUE_APP_STATIC_DIR;
}
