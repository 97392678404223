

















import { vxm } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import MyVaultProfileHealth from "@/views/widgets/MyVaultProfileHealth.vue";
import InstantEmergency from "@/views/widgets/InstantEmergency.vue";
import ActiveUsers from "@/views/widgets/ActiveUsers.vue";
import Casebook from "@/views/widgets/Casebook.vue";

@Component({
  components: {
    MyVaultProfileHealth,
    InstantEmergency,
    ActiveUsers,
    Casebook
  },
})
export default class DashAsideLeft extends Vue {
  private ui = vxm.ui;
  private isOnMobile() {
    return Boolean(getComputedStyle(this.$el).getPropertyValue("--is-mobile"));
  }

  private toggleMin() {
    this.ui.leftMinimize = !this.ui.leftMinimize;
  }
}
